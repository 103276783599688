<template>
<div>
    <Editor id="tinymce" v-model="content" :init="init"
    ></Editor>
</div>
</template>

<script>
    import ty from "../../utils/ty";
    import tinymce from "tinymce/tinymce";
    import "tinymce/themes/silver/theme";
    import "tinymce/icons/default/icons";
    import Editor from "@tinymce/tinymce-vue";
    import "tinymce/plugins/image";
    import "tinymce/plugins/link";
    import "tinymce/plugins/code";
    import "tinymce/plugins/table";
    import "tinymce/plugins/lists";
    import "tinymce/plugins/contextmenu";
    import "tinymce/plugins/wordcount";
    import "tinymce/plugins/colorpicker";
    import "tinymce/plugins/textcolor";
    import "tinymce/plugins/media"; //插入编辑媒体


    //引入方式引入node_modules里的tinymce相关文件文件
    import "tinymce/themes/silver"; //编辑器主题，不引入则报错
    import "tinymce/icons/default"; //引入编辑器图标icon，不引入则不显示对应图标

    // 引入编辑器插件
    import "tinymce/plugins/advlist"; //高级列表 有序 / 无序列表
    import "tinymce/plugins/anchor"; //锚点
    import "tinymce/plugins/autolink"; //自动链接
    import "tinymce/plugins/autoresize"; //编辑器高度自适应,注：plugins里引入此插件时，Init里设置的height将失效
    import "tinymce/plugins/autosave"; //自动存稿
    import "tinymce/plugins/directionality"; //文字方向
    import "tinymce/plugins/help"; //帮助
    import "tinymce/plugins/hr"; //水平分割线
    import "tinymce/plugins/image"; //插入编辑图片
    import "tinymce/plugins/importcss"; //引入css
    import "tinymce/plugins/insertdatetime"; //插入日期时间
    import "tinymce/plugins/link"; //超链接
    import "tinymce/plugins/lists"; //列表插件
    import "tinymce/plugins/media"; //插入编辑媒体
    import "tinymce/plugins/pagebreak"; //插入分页符
    import "tinymce/plugins/preview"; //预览
    import "tinymce/plugins/searchreplace"; //查找替换
    import "tinymce/plugins/table"; //表格
    import "tinymce/plugins/textcolor"; //文字颜色
    import "tinymce/plugins/visualchars"; //显示不可见字符
    import "tinymce/plugins/wordcount"; //字数统计
    import axios from "axios";


    export default {
        name: "TinymceEditor",
        components: { Editor },
        data() {
            return {
                content:this.value,
                init: {
                    language_url: "/tinymce/langs/zh_CN.js", //引入语言包文件
                    language: "zh_CN", //语言类型
                    content_style: "p {margin: 0px; border:0px ; padding: 0px;}",  //设置行间距
                    mode: "textareas",
                    entity_encoding: "raw",
                    remove_linebreaks: false,
                    force_p_newlines: false,
                    force_br_newlines: false,
                    forced_root_block: "",
                    skin_url: "/tinymce/skins/ui/oxide", //皮肤：浅色
                    // skin_url: '/tinymce/skins/ui/oxide-dark',//皮肤：暗色
                    //media
                    plugins: ['preview searchreplace autolink directionality visualchars image link  table hr pagebreak anchor insertdatetime advlist lists wordcount autosave '], //插件配置
                    // toolbar: this.toolbar, //工具栏配置，设为false则隐藏
                    // 工具栏1
                    toolbar1:
                        "forecolor backcolor bold italic underline strikethrough link anchor | " +
                        "alignleft aligncenter alignright alignjustify outdent indent " +
                        "indent2em lineheight | styleselect formatselect fontselect fontsizeselect",
                    // // 工具栏2 去掉的 print 打印  formatpainter axupimgs media 等等
                    toolbar2:
                        "ltr rtl bullist numlist | blockquote subscript superscript removeformat | table image  hr pagebreak insertdatetime  preview | searchreplace ",
                    // menubar: 'file edit',  //菜单栏配置，设为false则隐藏，不配置则默认显示全部菜单，也可自定义配置--查看 http://tinymce.ax-z.cn/configure/editor-appearance.php --搜索“自定义菜单”

                    fontsize_formats:
                        "12px 14px 16px 18px 20px 22px 24px 28px 32px 36px 48px 56px 72px", //字体大小
                    font_formats:
                        "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC;苹果苹方=PingFang SC,Microsoft YaHei;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei;Arial=arial,helvetica;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",

                    height: 500, //注：引入autoresize插件时，此属性失效
                    min_height: 400,
                    placeholder: "在这里输入文字",
                    branding: false, //tiny技术支持信息是否显示
                    resize: false, //编辑器宽高是否可变，false-否,true-高可变，'both'-宽高均可，注意引号
                    // statusbar: false,  //最下方的元素路径和字数统计那一栏是否显示
                    elementpath: false, //元素路径是否显示

                    content_style: "img {max-width:100%;}", //直接自定义可编辑区域的css样式
                    images_upload_handler: function (blobInfo, success, failure, progress) {
                        var xhr, formData;
                        xhr = new XMLHttpRequest();
                        xhr.withCredentials = false;
                        xhr.open("POST", ty.apiurl+"/common/uploadFileUrl?fileType=0&type=0");

                        xhr.upload.onprogress = function (e) {
                            progress((e.loaded / e.total) * 100);
                        };

                        xhr.onload = function () {
                            var json;
                            if (xhr.status == 403) {
                                failure("HTTP Error: " + xhr.status, { remove: true });
                                return;
                            }
                            if (xhr.status < 200 || xhr.status >= 300) {
                                failure("HTTP Error: " + xhr.status);
                                return;
                            }
                            json = JSON.parse(xhr.responseText);
                            console.log('json',json);
                            if (!json || typeof json.msg != "string") {
                                failure("Invalid JSON: " + xhr.responseText);
                                return;
                            }
                            success(json.msg);
                        };

                        xhr.onerror = function () {
                            failure(
                                "Image upload failed due to a XHR Transport error. Code: " +
                                xhr.status
                            );
                        };
                        formData = new FormData();
                        formData.append("file", blobInfo.blob(), blobInfo.filename());
                        xhr.send(formData);
                    },
                    file_picker_callback: function (callback, value, meta) {
                        if (meta.filetype === "media") {
                            // 模拟上传本地视频
                            let input = document.createElement("input");
                            input.setAttribute("type", "file");
                            input.setAttribute("accept", ".mp4");
                            input.onchange = function () {
                                let file = this.files[0];
                                // console.log(file);
                                let fd = new FormData();
                                fd.append("file", file);
                                fd.append("fileType",1)
                                fd.append("type",0)

                                axios.post("/common/uploadFileUrl", fd).then((res) => {
                                    console.log(res)
                                    let rr = res.msg;
                                    callback(res.data.msg);
                                });
                            };
                            input.click();
                        }
                    }
                },
            }
        },
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            type:{
                type:Number,
                default:0
            },
        },
        watch: {
            value: function(value) {
                this.content=value
            },
            content: function(newValue) {
                this.$emit('change', newValue)
            }
        },
        created() {
            tinymce.init({
                language: "en",
            });
        }

    }
</script>

<style scoped>

</style>
